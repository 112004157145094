// React
import React, { useMemo } from 'react';

import Image from 'next/legacy/image';
import Link from 'next/link';
import cc from 'classcat';
import t from 'prop-types';
import dayjs from 'dayjs';

// Utilities
import { useFormat, useLabels } from 'utilities/hooks';
import { useInitiativeDataStore } from 'utilities/store';

// Components
import Pill from 'components/pill';
import ReportType from 'components/reportType';

// Icons
import { FiActivity, FiExternalLink } from 'react-icons/fi';

const InitiativeRowComponent = ({
    item,
    reports,
    fundingRecipients,
    funders,
    limitedAccess,
}) => {
    // ///////////////////
    // STORES
    // ///////////////////

    const { CONSTANTS } = useInitiativeDataStore();

    // ///////////////////
    // HOOKS
    // ///////////////////

    const { label, getValueLabel } = useLabels();
    const format = useFormat();

    // ///////////////////
    // DATA
    // ///////////////////

    const {
        Name,
        Category__c,
        Grant_Start_Date__c,
        Grant_End_Date__c,
        Hero_Image_URL__c,
    } = item;

    const sortedReports = useMemo(
        () =>
            reports
                .sort(
                    (a, b) => new Date(a.Due_Date__c) - new Date(b.Due_Date__c)
                )
                .filter(report => report.Status__c !== 'Published')
                .filter(report => report.Report_Type__c !== 'Milestone')
                .filter(report => report.Funding_Report__c)
                .map(report => {
                    const dueDate = format.date(report.Due_Date__c);
                    const overdueDays = dayjs(dayjs()).diff(
                        report.Due_Date__c,
                        'day'
                    );
                    return {
                        ...report,
                        dueDate,
                        overdueDays,
                    };
                })
                .filter(report => report.overdueDays < 120),

        [reports]
    );

    const nextReport = sortedReports?.[0] ?? null;

    const pillThemes = {
        [CONSTANTS.REPORTS.REPORT_NOT_STARTED]: 'coral',
        [CONSTANTS.REPORTS.REPORT_IN_PROGRESS]: 'amber',
        [CONSTANTS.REPORTS.REPORT_IN_REVIEW]: 'amber',
        [CONSTANTS.REPORTS.REPORT_PUBLISHED]: 'teal',
    };

    // ///////////////////
    // RENDER
    // ///////////////////

    function wrapWithLink(children, link) {
        return !limitedAccess ? <Link href={link}>{children}</Link> : children;
    }

    return (
        <div
            className={cc([
                'flex flex-col justify-between p-16 bg-white md:flex-row rounded-8',
                {
                    'cursor-pointer': !limitedAccess,
                },
            ])}>
            <div className="flex">
                {/* Image/Icon */}
                <div className="flex-col items-center justify-center shrink-0 hidden space-y-12 w-128 min-h-128  rounded-8 sm:flex min-h-[128px] p-8 text-center relative">
                    {Hero_Image_URL__c ? (
                        <Image
                            src={Hero_Image_URL__c}
                            layout="fill"
                            objectFit="cover"
                            className="rounded-8"
                        />
                    ) : (
                        <FiActivity className="w-48 h-48" />
                    )}
                </div>

                {/* Content */}
                <div className="flex flex-col sm:ml-16 mr-80">
                    {limitedAccess && (
                        <div className="self-start px-8 py-4 mb-8 rounded-4 bg-amber-10">
                            <div className="-mb-4 text-amber-100 t-caption-bold">
                                {label('InitiativeManagerCardLimitedAccess')}
                            </div>
                        </div>
                    )}
                    <Pill theme="blue" className="inline-block mb-20">
                        {getValueLabel(
                            'Initiative__c.Category__c',
                            Category__c
                        )}
                    </Pill>
                    <div className="t-caption text-blue-60">
                        {format.fundingRecipientNamesFull(fundingRecipients)}
                    </div>
                    <div className="text-blue-100 t-h5">{Name}</div>
                    <div className="text-blue-60 t-footnote">
                        {label('InitiativeRowSupportedBy')}{' '}
                        {format.funderNames(funders)}
                    </div>
                </div>
            </div>

            {/* Dates */}
            <div className="flex justify-end mt-24 space-x-12 shrink-0 md:justify-start md:w-[30%] md:space-y-12 md:flex-col md:space-x-0 md:mt-0">
                {nextReport &&
                    wrapWithLink(
                        <div
                            className={cc([
                                'w-full p-8 space-y-4  border-4 sm:w-2/5 md:w-auto border-amber-20 rounded-4 ',
                                {
                                    'transition-colors group hover:border-amber-40 pr-0': !limitedAccess,
                                    'cursor-default': limitedAccess,
                                },
                            ])}>
                            <div className="flex items-center space-x-8">
                                <ReportType
                                    {...{
                                        type: nextReport.Report_Type__c,
                                        size: 'small',
                                    }}
                                />
                                <div className="flex flex-col">
                                    <div className="flex items-baseline space-x-8 whitespace-nowrap">
                                        <span className="text-blue-100 t-sh-7">
                                            {label(
                                                'InitiativeManagerCardDeadline'
                                            )}{' '}
                                        </span>
                                        <span className="t-footnote text-blue-60">
                                            {nextReport.dueDate}
                                        </span>
                                    </div>
                                    <div className="flex space-x-8 whitespace-nowrap">
                                        <span className="text-blue-100 t-caption-bold">
                                            {getValueLabel(
                                                'Initiative_Report__c.Report_Type__c',
                                                nextReport.Report_Type__c
                                            )}
                                        </span>
                                        <span className="t-footnote text-blue-60">
                                            <Pill
                                                theme={
                                                    pillThemes[
                                                        nextReport?.Status__c
                                                    ]
                                                }>
                                                {getValueLabel(
                                                    'Initiative_Report__c.Status__c',
                                                    nextReport?.Status__c
                                                )}
                                            </Pill>
                                        </span>
                                    </div>
                                </div>
                                {!limitedAccess && (
                                    <div className="!ml-auto -my-8 p-4 pl-6 flex-shrink-0 flex items-center self-stretch bg-amber-20 text-amber-120 group-hover:bg-amber-40 transition-colors">
                                        <FiExternalLink className="w-16 h-16" />
                                    </div>
                                )}
                            </div>
                        </div>,
                        `/${item.Id}/reports/${nextReport.Id}`
                    )}

                {Grant_Start_Date__c && Grant_End_Date__c && (
                    <div className="p-8 space-y-4 border-4 sm:w-2/5 md:w-auto border-amber-20 rounded-4">
                        <div className="t-sh7 text-blue-60">
                            {label('InitiativeManagerCardPeriod')}
                        </div>
                        <div className="text-blue-100 t-caption-bold">
                            <span className="mr-8">{`${format.date(
                                Grant_Start_Date__c
                            )} - ${format.date(Grant_End_Date__c)}`}</span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

InitiativeRowComponent.propTypes = {
    item: t.object.isRequired,
    reports: t.array,
    fundingRecipients: t.array,
    funders: t.array,
};

InitiativeRowComponent.defaultProps = {
    item: {},
    reports: [],
    fundingRecipients: [],
    funders: [],
};

export default InitiativeRowComponent;
