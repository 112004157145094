// React
import React from 'react';

// Packages
import t from 'prop-types';

// Utilities

// Components

const ListCount = ({ count }) => {
    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div className="flex justify-end mb-16 mr-16">
            <span className="ml-20 t-caption-bold">{count}</span>
        </div>
    );
};

ListCount.propTypes = {
    count: t.string.isRequired,
};

ListCount.defaultProps = {};

export default ListCount;
